type ExternalRedirectProps = {
  to: string;
};

/**
 * Redirects to the given path, replacing the current
 * window location.
 */
export const ExternalRedirect = ({ to }: ExternalRedirectProps) => {
  window.location.replace(to);

  return null;
};
