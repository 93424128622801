import { ComponentProps, useEffect, useState } from 'react';
import { ClassNames, DayPicker } from 'react-day-picker';
import styles from 'react-day-picker/dist/style.module.css';
import { cva } from 'class-variance-authority';

export type DateCalendarProps = ComponentProps<typeof DayPicker> & {
  todayHighlight?: boolean;
};

const todayHighlightAppearanceClassNames =
  'after:hidden after:w-full after:h-full after:absolute after:box-content after:inset-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:p-xxs after:rounded-circle after:border-[1px] after:border-content after:-z-10';

const todayHighlightVisibilityClassNames = '*:after:flex';

const navButtonsClassNames =
  'rounded-circle hover:bg-hover-opacity active:bg-active-opacity focus:ring focus:ring-focus outline-none';

const availableDateColorClassNames =
  '!bg-accent-subtle hover:!bg-accent-subtle-hover active:!bg-accent-subtle-active';
const disabledDateColorClassNames =
  'disabled:!bg-transparent disabled:hover:bg-transparent disabled:active:bg-transparent';
const selectedDateColorClassNames =
  '*:!text-content-inverse *:!bg-primary *:hover:!bg-primary-hover *:active:!bg-primary-active';

const classNames: Partial<ClassNames> = {
  ...styles,
  button_next: `${styles['button_next']} ${navButtonsClassNames} ml-xs`,
  button_previous: `${styles['button_previous']} ${navButtonsClassNames}`,
  chevron: `${styles['chevron']} fill-content h-l w-l`,
  day: `${styles.day} flex items-center justify-center aspect-square text-body-s text-content relative border-transparent rounded-circle focus-within:z-10`,
  day_button: `${styles['day_button']} !rounded-circle outline-none focus:ring !border-none focus:ring-focus ${availableDateColorClassNames} ${disabledDateColorClassNames} ${todayHighlightAppearanceClassNames}`,
  disabled: `${styles['disabled']} cursor-default text-body-s ${disabledDateColorClassNames}`,
  month_caption: `${styles['month_caption']} text-heading-s mb-xs`,
  selected: `${styles['selected']} !text-button-s ${selectedDateColorClassNames}`,
  today: `${styles['today']} !text-content`,
  week: `grid grid-cols-7 gap-xs my-xs`,
  weekday: `${styles['weekday']} text-body-s text-content-subdued font-normal`,
  weekdays: `grid grid-cols-7 gap-m text-center text-body-s pb-s border-b border-border-default`,
};

const today = cva(classNames.today, {
  variants: {
    highlight: {
      withHighlight: todayHighlightVisibilityClassNames,
      default: '',
    },
  },
  defaultVariants: {
    highlight: 'default',
  },
});

/**
  A wrapper around React Day Picker that can be used for showing
  a persisting calendar day picker in the UI.
*/

export const DateCalendar = ({
  defaultMonth,
  onMonthChange,
  todayHighlight,
  ...restProps
}: DateCalendarProps) => {
  const [month, setSelectedMonth] = useState<Date>(defaultMonth ?? new Date());

  const calendarClassNames = {
    ...classNames,
    today: today({ highlight: todayHighlight ? 'withHighlight' : 'default' }),
  };

  useEffect(() => {
    defaultMonth && setSelectedMonth(defaultMonth);
  }, [defaultMonth]);

  return (
    <DayPicker
      {...restProps}
      classNames={calendarClassNames}
      month={month}
      onMonthChange={(date) => {
        setSelectedMonth(date);
        onMonthChange?.(date);
      }}
    />
  );
};
