import { useTranslation } from 'react-i18next';
import { ErrorCard, LoadingPage, usePatientProfile } from '@pm/core';
import {
  type DashboardItem,
  useGetPatientTreatmentOverviewQuery,
} from '@pm/graphql';
import { WelcomeMessage } from './WelcomeMessage';
import { YourTreatments } from './YourTreatments';
import { ExploreTreatments } from './ExploreTreatments';
import { ActionItems } from './ActionItems';
import { VerificationCard } from './VerificationCard';
import { useAdditionalTreatments } from '../hooks/additional-treatments';

export const TreatmentOverviewPage = () => {
  const { t } = useTranslation('treatments', {
    keyPrefix: 'TreatmentOverview',
  });
  const { profile, loading: profileLoading } = usePatientProfile();
  const { data, loading: treatmentsLoading } =
    useGetPatientTreatmentOverviewQuery({ fetchPolicy: 'network-only' });
  const additionalTreatmentCards = useAdditionalTreatments({
    available: profile.additionalTreatmentsAvailable,
  });

  if (profileLoading || treatmentsLoading) {
    return <LoadingPage />;
  }

  if (!profile) {
    return (
      <ErrorCard
        title={t('Errors.Generic.Title')}
        message={t('Errors.Generic.Message')}
      />
    );
  }

  const onboardingCards = (data?.patientTreatmentOverview
    ?.onboardingDashboardItems ?? []) as DashboardItem[];
  const treatmentCards = (data?.patientTreatmentOverview
    ?.treatmentDashboardItems ?? []) as DashboardItem[];
  const verificationCard = (data?.patientTreatmentOverview
    ?.verificationDashboardItem ?? null) as DashboardItem;

  return (
    <div>
      {/* TODO: address top padding in layout ticket: */}
      {/* https://github.com/purposemed/core/issues/8498 */}
      <div className="flex-col pt-xl space-y-xxl">
        <WelcomeMessage
          preferredName={profile.preferredName || profile.firstName}
        />
        {verificationCard && <VerificationCard />}
        <ActionItems actionItemCards={onboardingCards} />
        <YourTreatments treatmentCards={treatmentCards} />
        <ExploreTreatments treatmentCards={additionalTreatmentCards} />
      </div>
    </div>
  );
};
