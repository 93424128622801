import { ButtonLink, Card, IconBox, Text } from '@pm/ui';

export const VerificationCard = () => {
  const iconBackgroundColor = 'bg-warning-subtle';
  const iconColor = 'text-content-warning';

  return (
    <Card state="warning">
      <div className="flex">
        <IconBox
          icon={'warning'}
          iconColor={iconColor}
          className={iconBackgroundColor}
        />
        <div className="flex flex-col pt-xs gap-y-s">
          <Text size="bodyL"> </Text>
          <ButtonLink
            intent="ghost"
            size="small"
            width="fit"
            aria-label={`Verify now`}
            to={'/'}
          ></ButtonLink>
        </div>
      </div>
    </Card>
  );
};
