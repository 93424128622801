import { useTranslation } from 'react-i18next';
import { Text } from '@pm/ui';
import { type DashboardItem } from '@pm/graphql';
import { TreatmentCard } from '../TreatmentCard/TreatmentCard';

type YourTreatmentsProps = { treatmentCards: (DashboardItem | undefined)[] };

export const YourTreatments = ({ treatmentCards }: YourTreatmentsProps) => {
  const { t } = useTranslation('treatments', {
    keyPrefix: 'TreatmentOverview.YourTreatments',
  });

  if (!treatmentCards.length) {
    return null;
  }

  return (
    <div className="flex-col space-y-m">
      <Text size="headingS">{t('Title')}</Text>
      {treatmentCards.map((card) => (
        <TreatmentCard key={card?.name} />
      ))}
    </div>
  );
};
