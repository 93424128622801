import { FormRadioGroup } from '@pm/forms';
import { SingleSelectChip, Text } from '@pm/ui';
import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

type TimeSlotGroupProps = Omit<
  ComponentProps<typeof FormRadioGroup>,
  'RadioComponent'
> & {
  children: React.ReactNode;
};

export const TimeSlotGroup = ({
  children: label,
  ...radioGroupProps
}: TimeSlotGroupProps) => {
  const { t } = useTranslation('core', { keyPrefix: 'components.timepicker' });

  return (
    <div className="grid grid-cols-[100px_auto] items-baseline">
      {label}
      {radioGroupProps.options.length > 0 ? (
        <FormRadioGroup
          {...radioGroupProps}
          orientation="horizontal"
          RadioComponent={TimeOption}
        />
      ) : (
        <Text size="label">{t('noAvailableTimes')}</Text>
      )}
    </div>
  );
};

type TimeOptionProps = ComponentProps<typeof SingleSelectChip>;

export const TimeOption = (props: TimeOptionProps) => (
  <SingleSelectChip {...props} textWidth="fixed-80" />
);
