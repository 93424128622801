import { Navigate, NavigateProps } from 'react-router-dom';
import { usePathWithParams } from '@pm/core';

type RedirectWithParamsProps = NavigateProps;

/**
 * Redirects to the given path, appending the current search params
 * to the new path.
 */
export const RedirectWithParams = ({
  to,
  ...navigateProps
}: RedirectWithParamsProps) => {
  const buildPathWithParams = usePathWithParams();

  return <Navigate to={buildPathWithParams({ to })} {...navigateProps} />;
};
