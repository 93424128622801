import { ReactNode } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import { cva, type VariantProps } from 'class-variance-authority';
import { LoadingSpinner } from '@pm/core';

const list = cva('w-full flex-col');

/**
 * *** Props ***
 *
 * `defaultValue` [string] -- if defaultValue === listItem.value, the accordion will be expanded
 * on that list item by default. combine this with `ListItem collapsible={false}` for a ListItem that
 * is always expanded by default.
 *
 * `collapsible` [boolean] -- if true, the ListItem children will be expandable.
 *
 * `type` ['single' | 'multiple'] -- determines the behaviour of the List and its children; if `type === 'single'`,
 * expanding one item will close the currently expanded one; if `type === 'multiple'`, each item will be
 * individually expandable at the same time.
 *
 */
type ListProps = VariantProps<typeof list> &
  (Accordion.AccordionSingleProps | Accordion.AccordionMultipleProps) & {
    children: ReactNode;
    loading?: boolean;
  };

export const List = ({ children, loading, ...props }: ListProps) => {
  return (
    <Accordion.Root className={list()} {...props}>
      <ul>{children}</ul>
      {loading && (
        <div className="flex justify-center mt-xs">
          <LoadingSpinner size="2x" />
        </div>
      )}
    </Accordion.Root>
  );
};
