import { FormField } from '@pm/forms';
import { Input, Text } from '@pm/ui';
import { FormEvent } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TimeSlotGroup } from './TimeSlotGroup';

type AppointmentTimeSlot = {
  time: Date;
  providerId?: string;
};

type TimePickerProps = {
  timeSlots: AppointmentTimeSlot[];
  timeFieldName: string;
  providerFieldName: string;
};

export const TimePicker = ({
  timeSlots,
  timeFieldName,
  providerFieldName,
}: TimePickerProps) => {
  const { t } = useTranslation('core', { keyPrefix: 'components.timepicker' });
  const { field: providerField } = useController({ name: providerFieldName });

  const toTimeOption = ({ time }: { time: Date }) => ({
    text: t('timeWithoutTimezone', { date: time }),
    value: time.toISOString(),
  });

  const handleTimeSelection = (time: string | FormEvent<HTMLDivElement>) => {
    const providerId =
      timeSlots.find((timeSlot) => timeSlot.time.toISOString() === time)
        ?.providerId ?? '';

    providerField.onChange(providerId);
  };

  const morningOptions = timeSlots.filter(({ time }) => time.getHours() < 12);
  const afternoonOptions = timeSlots.filter(
    ({ time }) => time.getHours() >= 12,
  );

  return (
    <div className="space-y-l">
      <TimeSlotGroup
        aria-describedby="morning-times-description"
        aria-labelledby="morning-times-label"
        name={timeFieldName}
        options={morningOptions.map(toTimeOption)}
        onChange={handleTimeSelection}
      >
        <Text size="label" id="morning-times-label">
          {t('morning.label')}
        </Text>
        <Text hidden aria-hidden id="morning-times-description">
          {t('morning.description')}
        </Text>
      </TimeSlotGroup>

      <TimeSlotGroup
        aria-describedby="afternoon-times-description"
        aria-labelledby="afternoon-times-label"
        name={timeFieldName}
        options={afternoonOptions.map(toTimeOption)}
        onChange={handleTimeSelection}
      >
        <Text size="label" id="afternoon-times-label">
          {t('afternoon.label')}
        </Text>
        <Text hidden aria-hidden id="afternoon-times-description">
          {t('afternoon.description')}
        </Text>
      </TimeSlotGroup>
      <FormField
        type="hidden"
        name={providerFieldName}
        Component={Input}
        value={providerField.value ?? ''}
        aria-hidden
        hidden
        disabled
      />
    </div>
  );
};
