import { Path, useSearchParams } from 'react-router-dom';

const buildPathWithParams = ({
  path,
  searchParams,
}: {
  path: string | Partial<Path>;
  searchParams: URLSearchParams;
}) => {
  const searchParamsString = searchParams.toString();

  if (searchParamsString === '') {
    return path;
  }

  return `${path}?${searchParamsString}`;
};

/**
 * Builds a path, appending the current search params to the new path.
 */
export const usePathWithParams = () => {
  const [searchParams] = useSearchParams();

  return ({ to }: { to: string | Partial<Path> }) =>
    buildPathWithParams({ path: to, searchParams });
};
