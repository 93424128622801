import { ReactNode } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import { cva, VariantProps } from 'class-variance-authority';
import { Link } from 'react-router-dom';
import { Icon } from '../Icon/Icon';
import { Text } from '../Text/Text';

const listItem = cva(
  'py-s px-m inline-flex last:border-b last:border-x first:border-t first:border-x border-x border-b first:rounded-t-m last:rounded-b-m aria-disabled:bg-surface-disabled aria-disabled:text-content-disabled aria-disabled:cursor-not-allowed aria-disabled:pointer-events-none',
  { variants: { width: { full: 'w-full', fit: 'w-fit' } } },
);

/**
 * *** Props ***
 *
 * `collapsible` [boolean] -- determines whether to show the chevron expander button. if false,
 */
type ListItemProps = VariantProps<typeof listItem> & {
  heading: string;
  subHeading?: string;
  linkTo?: string;
  value: string;
  leadingElement?: ReactNode;
  trailingElement?: ReactNode;
  children?: ReactNode;
  collapsible?: boolean;
  disabled?: boolean;
};

export const ListItem = ({
  heading,
  subHeading,
  children,
  value,
  leadingElement,
  trailingElement,
  linkTo,
  width = 'full',
  collapsible = false,
  disabled = false,
  ...props
}: ListItemProps) => {
  return (
    <div className={listItem({ width })} aria-disabled={disabled} {...props}>
      <li className="w-full">
        <Accordion.Item
          value={value}
          className="space-y-s"
          aria-label={heading}
        >
          <Accordion.Header className="flex gap-m">
            {leadingElement && <div className="my-auto">{leadingElement}</div>}
            <div className="flex-col">
              {linkTo ? (
                <Link to={linkTo} aria-disabled={disabled}>
                  <Text size="bodyL" color="primary">
                    {heading}
                  </Text>
                </Link>
              ) : (
                <Text size="bodyL">{heading}</Text>
              )}
              <Text size="bodyL" color="contentSubdued">
                {subHeading}
              </Text>
            </div>
            {collapsible && (
              <Accordion.Trigger className="group ml-auto">
                <Icon
                  name="keyboard_arrow_down"
                  animation="transform duration-200 group-rdx-state-open:rotate-180"
                  label="expand-collapse"
                />
              </Accordion.Trigger>
            )}
            {trailingElement && (
              <div className="ml-auto my-auto">{trailingElement}</div>
            )}
          </Accordion.Header>

          <Accordion.Content className="overflow-auto max-h-[256px]">
            {children}
          </Accordion.Content>
        </Accordion.Item>
      </li>
    </div>
  );
};
