import * as RadioPrimitive from '@radix-ui/react-radio-group';
import { cva, VariantProps } from 'class-variance-authority';
import { useId } from 'react';
import { twMerge } from 'tailwind-merge';
import { WithIcon } from '../../../types/component.types';
import { chip } from '../../Chip/Chip';
import { Text } from '../../Text/Text';
import { TextFieldProps } from '../TextField/TextField';

const singleSelectChip = cva('', {
  variants: {
    intent: {
      default:
        'cursor-pointer rdx-state-unchecked:bg-surface rdx-state-unchecked:hover:bg-hover-opacity rdx-state-checked:bg-primary rdx-state-checked:text-surface rdx-state-checked:hover:bg-primary-hover',
      disabled: 'cursor-not-allowed bg-surface-disabled text-content-disabled',
    },
    width: {
      default: '',
      'fixed-80': 'w-[80px]',
    },
  },
  defaultVariants: {
    intent: 'default',
  },
});

const chipText = cva('max-w-[127px]', {
  variants: {
    textWidth: {
      default: '',
      'fixed-80': 'w-[80px]',
    },
  },
  defaultVariants: {
    textWidth: 'default',
  },
});

type SingleSelectChipProps = RadioPrimitive.RadioGroupItemProps &
  WithIcon &
  VariantProps<typeof chipText> & {
    text: string;
    disabled?: boolean;
    // TODO: Implement free text?
    // https://github.com/purposemed/core/issues/11841
    freeText?: TextFieldProps;
  };

/**
 * A single select chip is a radio primitive styled as a chip, used for
 * selecting a single option from a list.
 * - This component should only be used as part of a radio group.
 * - Use `Chip` instead if you need a standalone chip that's not an input.
 * - Chip text should not exceed 20 characters, and if it does, the text will be truncated with an ellipses.
 *
 * @example
 * ```tsx
 * <SingleSelectChip text="My cool option" value="myCoolOption">Click me!</Button>
 * ```
 */
export const SingleSelectChip = ({
  disabled,
  value,
  required,
  text,
  leadingIcon,
  trailingIcon,
  onClick,
  freeText,
  textWidth,
  ...props
}: SingleSelectChipProps) => {
  const id = useId();
  const intent = disabled ? 'disabled' : 'default';

  return (
    <label htmlFor={id}>
      <RadioPrimitive.Item
        {...props}
        id={id}
        aria-disabled={disabled}
        value={value}
        required={required}
        className={twMerge(chip(), singleSelectChip({ intent }))}
        onClick={disabled ? (e) => e.preventDefault() : onClick}
      >
        {leadingIcon && leadingIcon}
        <div className={chipText({ textWidth })}>
          <Text
            size="bodyS"
            select="none"
            color="contentDefault"
            overflow="truncate"
            title={text}
          >
            {text}
          </Text>
        </div>
        {trailingIcon && trailingIcon}
      </RadioPrimitive.Item>
    </label>
  );
};
