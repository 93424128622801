import { MutationHookOptions } from '@apollo/client/index.js';
import { StartQuestionnaireMutation } from '@pm/graphql';
import { ComponentProps } from 'react';
import { WorkflowStepCard } from '../..';
import { type QuestionnaireDefinition } from '../../types';
import { useQuestionnaire } from './use-questionnaire';

type WorkflowStepCardProps = ComponentProps<typeof WorkflowStepCard>;

interface QuestionnaireWorkflowStepCardProps
  extends Omit<WorkflowStepCardProps, 'action'> {
  workflowStepId?: string;
  definitionName: QuestionnaireDefinition;
  onError: MutationHookOptions<StartQuestionnaireMutation>['onError'];
  onSuccess: (patientResponseId: string) => void;
  action: Pick<
    WorkflowStepCardProps['action'],
    'text' | 'intent' | 'trailingIcon'
  >;
  secondaryAction?: Pick<
    WorkflowStepCardProps['action'],
    'text' | 'intent' | 'onClick'
  >;
}

export const QuestionnaireWorkflowStepCard = ({
  workflowStepId,
  definitionName,
  onError,
  title,
  description,
  action,
  secondaryAction,
  onSuccess,
}: QuestionnaireWorkflowStepCardProps) => {
  const { startQuestionnaire, loading } = useQuestionnaire({
    onSuccess,
    onError,
    definitionName,
    workflowStepId,
  });

  return (
    <WorkflowStepCard
      title={title}
      description={description}
      action={{
        ...action,
        onClick: startQuestionnaire,
        disabled: loading,
      }}
      secondaryAction={secondaryAction}
    />
  );
};
